@for (config of editUserControlConfigs; track $index) {
  @switch (config.type) {
    @default {
      <mat-form-field color="accent" floatLabel="auto">
        <mat-label>{{ translationService.translations.common[config.translationKey] }}</mat-label>
        <input
          matInput
          [formControl]="$any(editUserForm).controls[config.name]"
          [placeholder]="config.placeholder"
          [required]="config.required"
          #input
          autocomplete="off"
        />
        @if (config.type === 'phone') {
          <mat-icon matIconSuffix [svgIcon]="'enthus-phone'"></mat-icon>
        }
        @if (config.maxLength && config.type !== 'email' && editUserForm.controls[config.name].dirty) {
          <mat-hint align="end">{{ input.value.length }} / {{ config.maxLength }}</mat-hint>
        }
      </mat-form-field>
    }
    @case ('date') {
      <mat-form-field color="accent" floatLabel="auto">
        <mat-label>{{ translationService.translations.common[config.translationKey] }}</mat-label>
        <input
          matInput
          [formControl]="$any(editUserForm).controls[config.name]"
          [matDatepicker]="datePicker"
          [required]="config.required"
          (click)="datePicker.open()"
          [min]="userService.minDate"
          [max]="userService.maxDate"
          readonly
          tabindex="-1"
        />
        <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker
          [touchUi]="(appService.isMobile$ | async) === true && !appService.desktopIsForced"
          [startView]="'multi-year'"
          [startAt]="startDate"
          [color]="'accent'"
          #datePicker
        ></mat-datepicker>
      </mat-form-field>
    }
    @case ('select') {
      <mat-form-field color="accent">
        <mat-label>{{ translationService.translations.common[config.translationKey] }}</mat-label>
        <mat-select [formControl]="$any(editUserForm).controls[config.name]" [placeholder]="config.placeholder" [required]="config.required">
          @if (config.name === 'UserSexId') {
            @for (sex of dropdownValuesService.dropdownValues$.value?.Sex; track $index) {
              <mat-option [value]="sex.SexId">
                {{ sex.SexDescription[translationService.locale] }}
              </mat-option>
            }
          }
          @if (config.name === 'UserLanguageId') {
            @for (language of dropdownValuesService.dropdownValues$.value?.Language; track $index) {
              <mat-option [value]="language.LanguageId">
                {{ language.LanguageDescription[translationService.locale] }}
              </mat-option>
            }
          } @else if (config.name === 'UserCostCenterUUID') {
            <mat-option [value]="costCenterService.noCostCenterUUID">
              {{ translationService.translations.labels.NoCostCenter }}
            </mat-option>
            @for (costCenter of costCenters$ | async; track $index) {
              <mat-option [value]="costCenter.UUID">{{ costCenter.Description }}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    }
    @case ('chip-autocomplete') {
      @if (config.name === 'UserAdvertisingInterestUUIDs') {
        <portal-advertising-interests-autocomplete
          [autocompleteAdvertisingInterests]="dropdownValuesService.dropdownValues$.value?.AdvertisingInterests"
          [selectedAdvertisingInterests]="selectedAdvertisingInterests"
          (selectedAdvertisingInterestsChange)="selectedAdvertisingInterestsChange($event)"
        ></portal-advertising-interests-autocomplete>
      }
    }
  }
}
